import classNames from "classnames/bind";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import PropTypes from "prop-types";
import * as React from "react";
import ReactPlayer from "react-player/lazy";
import * as mediaStyles from "./media.module.css";

const cx = classNames.bind(mediaStyles);

const Media = ({media, big, imageProps, className}) => {
    const getMedia = () => {
        if (media.content) {
            const isVideo = media.content.mime?.indexOf("video/") === 0;
            if (isVideo) {
                return (
                    <div className={mediaStyles.media__playerWrapper}>
                        <ReactPlayer
                            className={mediaStyles.media__player}
                            url={media.content.url}
                            height="100%"
                            width="100%"
                            playsinline
                            muted
                            playing
                            loop
                        />
                    </div>
                );
            }

            const image = !isVideo && getImage(media.content.localFile);

            const rawImageProps =
                (media.content.width || media.content.localFile?.width) !== undefined
                    ? {
                          width: media.content.width || media.content.localFile?.width,
                          height: media.content.height || media.content.localFile?.height,
                          style: {maxWidth: `${media.content.width || media.content.localFile?.width}px`},
                      }
                    : {};

            return image ? (
                <GatsbyImage alt="" image={image} {...imageProps} />
            ) : (
                <img
                    className={mediaStyles.media__image}
                    src={media.content.localFile?.publicURL}
                    alt=""
                    {...rawImageProps}
                />
            );
        }

        if (!media.youtubeVideoURL) {
            return null;
        }

        return (
            <div className={cx({media__playerWrapper: true, media__playerWrapper_big: big})}>
                <ReactPlayer
                    className={mediaStyles.media__player}
                    url={media.youtubeVideoURL?.trim()}
                    light
                    playsinline
                    playing
                    height="100%"
                    width="100%"
                />
            </div>
        );
    };

    return <div className={cx({media: true}, className)}>{media && getMedia()}</div>;
};

Media.defaultProps = {
    big: false,
    imageProps: {},
    className: "",
};

Media.propTypes = {
    media: PropTypes.shape({
        youtubeVideoURL: PropTypes.string,
        content: PropTypes.shape({
            mime: PropTypes.string.isRequired,
            width: PropTypes.number,
            height: PropTypes.number,
            localFile: PropTypes.shape({
                publicURL: PropTypes.string,
                childImageSharp: PropTypes.shape({
                    gatsbyImageData: PropTypes.shape({}),
                }),
                svg: PropTypes.shape({
                    content: PropTypes.string,
                }),
            }),
        }),
    }).isRequired,
    big: PropTypes.bool,
    imageProps: PropTypes.shape({}),
    className: PropTypes.string,
};

export default Media;
